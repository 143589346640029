/* eslint-disable react/prop-types, react/no-danger */
import React from 'react'
import Button from '../button'
import './styles.scss'

const DashedHeading = ({ align, button, className, description, title }) => (
  <div className={`heading image-overlay ${className}`}>
    <div className={align ? `text-${align}` : 'text-center'}>
      <h2 className="font-head mb-4">
        <span>&nbsp; {title} &nbsp;</span>
        <span>&nbsp; {title} &nbsp;</span>
      </h2>
      {description && (
        <div
          className="heading-description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {button && (
        <Button className="text-center" name={button.title} href={button.url} />
      )}
    </div>
  </div>
)

export default DashedHeading
