import React from 'react'
import { Breadcrumb } from 'react-bootstrap'
import propTypes from 'prop-types'
import Link from '../link'

const Breadcrumbs = ({ route }) => {
  return (
    <Breadcrumb className="breadcrumbs mt-2">
      {route.map((link, index) =>
        index === route.length - 1 ? (
          <Breadcrumb.Item active key={link.title}>
            {link.title}
          </Breadcrumb.Item>
        ) : (
          <Breadcrumb.Item key={link.title}>
            <Link to={link.path}>{link.title}</Link>
          </Breadcrumb.Item>
        )
      )}
    </Breadcrumb>
  )
}

Breadcrumbs.propTypes = {
  route: propTypes.arrayOf(
    propTypes.shape({
      title: propTypes.string.isRequired,
      path: propTypes.string,
    })
  ).isRequired,
}

export default Breadcrumbs
