/* eslint-disable react/destructuring-assignment, react/prop-types */
import React from 'react'

import defaultBannerImg from '../../../../assets/images/booke-vertical-yellow-claw.png'

import './styles.scss'

const DefaultBanner = props => {
  return (
    <div
      className={`${
        props.backgroundImg ? 'banner fullwidth-background' : 'banner'
      } ${props.bannerClass}`}
      style={
        props.backgroundImg
          ? { backgroundImage: `url(${props.backgroundImg})` }
          : {}
      }
    >
      <h1 className="banner-title">{props.title}</h1>
      {props.img && (
        <img
          className="banner-image m-0"
          src={props.img === 'default' ? defaultBannerImg : props.img}
          alt={`Bannière ${props.title}`}
        />
      )}
    </div>
  )
}

export default DefaultBanner
