import React from 'react'
import { Container } from 'react-bootstrap'
import propTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '../../layouts/default'
import Banner from '../../components/sections/_banner/default'
import Breadcrumbs from '../../components/app-components/breadcrumbs'
import Heading from '../../components/app-components/dashed-heading'

import './styles.scss'

const Error404Template = ({
  data: {
    wpPage: { acf, seo },
  },
}) => {
  return (
    <Layout seo={seo}>
      <Banner
        title={acf.header_title}
        backgroundImg={
          acf.header_image_file ? acf.header_image_file.sourceUrl : ''
        }
      />
      <Container>
        <Breadcrumbs
          route={[{ title: 'Accueil', path: '/' }, { title: 'Erreur 404' }]}
        />
        <Heading
          title={acf.simpleTextTitle}
          description={acf.simpleTextDescription}
          button={acf.simpleTextCta}
          className="simple-text-404"
        />
      </Container>
    </Layout>
  )
}
export const query = graphql`
  query Page404 {
    wpPage(slug: { eq: "page-non-trouvee" }) {
      template {
        ... on WpTemplate_Error404 {
          acfError {
            fieldGroupName
            #        header_title
            #        header_image_file {
            #          source_url
            #        }
            #        simpleTextCta {
            #          title
            #          url
            #        }
            #        simpleTextDescription
            #        simple_text_title
          }
        }
      }
      ...yoastMeta
    }
  }
`

Error404Template.propTypes = {
  data: propTypes.objectOf(propTypes.any).isRequired,
}
export default Error404Template
